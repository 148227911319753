import React from 'react';
import { usePomodoroStore, TimerMode } from '../../store/pomodoroStore';

export const ModeSelector: React.FC = () => {
  const { mode, switchMode } = usePomodoroStore();

  const modes: { id: TimerMode; label: string }[] = [
    { id: 'work', label: 'Pomodoro' },
    { id: 'shortBreak', label: 'Short Break' },
    { id: 'longBreak', label: 'Long Break' },
  ];

  return (
    <div className="flex flex-wrap justify-center gap-2">
      {modes.map(({ id, label }) => (
        <button
          key={id}
          onClick={() => switchMode(id)}
          className={`px-3 py-2 text-sm sm:text-base rounded-lg font-medium transition-colors ${
            mode === id
              ? 'bg-white text-gray-800'
              : 'text-white/80 hover:bg-white/10'
          }`}
        >
          {label}
        </button>
      ))}
    </div>
  );
};