import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type TimerMode = 'work' | 'shortBreak' | 'longBreak';

interface Settings {
  workDuration: number;
  shortBreakDuration: number;
  longBreakDuration: number;
  sessionsUntilLongBreak: number;
}

interface PomodoroState {
  isRunning: boolean;
  mode: TimerMode;
  timeLeft: number;
  completedSessions: number;
  settings: Settings;
  tasks: Task[];
  activeTaskId: string | null;
  startTimer: () => void;
  pauseTimer: () => void;
  resetTimer: () => void;
  switchMode: (mode: TimerMode) => void;
  updateSettings: (settings: Partial<Settings>) => void;
  addTask: (task: Omit<Task, 'id'>) => void;
  updateTask: (id: string, updates: Partial<Task>) => void;
  deleteTask: (id: string) => void;
  setActiveTask: (id: string | null) => void;
  completeSession: () => void;
}

interface Task {
  id: string;
  title: string;
  estimatedPomodoros: number;
  completedPomodoros: number;
  completed: boolean;
}

const DEFAULT_SETTINGS: Settings = {
  workDuration: 25 * 60,
  shortBreakDuration: 5 * 60,
  longBreakDuration: 15 * 60,
  sessionsUntilLongBreak: 4,
};

export const usePomodoroStore = create<PomodoroState>()(
  persist(
    (set, get) => ({
      isRunning: false,
      mode: 'work',
      timeLeft: DEFAULT_SETTINGS.workDuration,
      completedSessions: 0,
      settings: DEFAULT_SETTINGS,
      tasks: [],
      activeTaskId: null,

      startTimer: () => set({ isRunning: true }),
      
      pauseTimer: () => set({ isRunning: false }),
      
      resetTimer: () => {
        const { settings, mode } = get();
        const duration = mode === 'work' 
          ? settings.workDuration 
          : mode === 'shortBreak'
          ? settings.shortBreakDuration
          : settings.longBreakDuration;
        set({ timeLeft: duration, isRunning: false });
      },

      switchMode: (newMode) => {
        const { settings } = get();
        const duration = newMode === 'work' 
          ? settings.workDuration 
          : newMode === 'shortBreak'
          ? settings.shortBreakDuration
          : settings.longBreakDuration;
        set({ mode: newMode, timeLeft: duration, isRunning: false });
      },

      updateSettings: (newSettings) => {
        const { settings: currentSettings, resetTimer } = get();
        const settings = { ...currentSettings, ...newSettings };
        set({ settings });
        resetTimer();
      },

      addTask: (task) => set((state) => ({
        tasks: [...state.tasks, { ...task, id: crypto.randomUUID() }],
      })),

      updateTask: (id, updates) => set((state) => ({
        tasks: state.tasks.map((task) =>
          task.id === id ? { ...task, ...updates } : task
        ),
      })),

      deleteTask: (id) => set((state) => ({
        tasks: state.tasks.filter((task) => task.id !== id),
        activeTaskId: state.activeTaskId === id ? null : state.activeTaskId,
      })),

      setActiveTask: (id) => set({ activeTaskId: id }),

      completeSession: () => set((state) => {
        const newCompletedSessions = state.completedSessions + 1;
        const { settings, activeTaskId } = state;
        
        const updatedTasks = activeTaskId
          ? state.tasks.map((task) =>
              task.id === activeTaskId
                ? { ...task, completedPomodoros: task.completedPomodoros + 1 }
                : task
            )
          : state.tasks;

        let nextMode: TimerMode = 'shortBreak';
        if (newCompletedSessions % settings.sessionsUntilLongBreak === 0) {
          nextMode = 'longBreak';
        }

        return {
          completedSessions: newCompletedSessions,
          mode: nextMode,
          timeLeft: nextMode === 'longBreak' 
            ? settings.longBreakDuration 
            : settings.shortBreakDuration,
          isRunning: false,
          tasks: updatedTasks,
        };
      }),
    }),
    {
      name: 'pomodoro-storage',
    }
  )
);