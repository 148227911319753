import React from 'react';
import { Settings as SettingsIcon, X } from 'lucide-react';
import { usePomodoroStore } from '../../store/pomodoroStore';

interface SettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Settings: React.FC<SettingsProps> = ({ isOpen, onClose }) => {
  const { settings, updateSettings } = usePomodoroStore();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <SettingsIcon className="w-5 h-5 text-gray-600" />
            <h2 className="text-xl font-semibold text-gray-800">Timer Settings</h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Work Duration (minutes)
            </label>
            <input
              type="number"
              value={settings.workDuration / 60}
              onChange={(e) => updateSettings({ workDuration: parseInt(e.target.value) * 60 })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
              min="1"
              max="60"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Short Break Duration (minutes)
            </label>
            <input
              type="number"
              value={settings.shortBreakDuration / 60}
              onChange={(e) => updateSettings({ shortBreakDuration: parseInt(e.target.value) * 60 })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
              min="1"
              max="30"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Long Break Duration (minutes)
            </label>
            <input
              type="number"
              value={settings.longBreakDuration / 60}
              onChange={(e) => updateSettings({ longBreakDuration: parseInt(e.target.value) * 60 })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
              min="1"
              max="60"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Sessions Until Long Break
            </label>
            <input
              type="number"
              value={settings.sessionsUntilLongBreak}
              onChange={(e) => updateSettings({ sessionsUntilLongBreak: parseInt(e.target.value) })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
              min="1"
              max="10"
            />
          </div>
        </div>
      </div>
    </div>
  );
};