import React from 'react';
import { format } from 'date-fns';
import { X } from 'lucide-react';

interface FullScreenClockProps {
  is24Hour: boolean;
  onClose: () => void;
}

export const FullScreenClock: React.FC<FullScreenClockProps> = ({ is24Hour, onClose }) => {
  const [time, setTime] = React.useState(new Date());

  React.useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="fixed inset-0 bg-gray-50 dark:bg-gray-900 z-50 flex flex-col items-center justify-center">
      <button
        onClick={onClose}
        className="absolute top-4 right-4 p-2 rounded-lg bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
      >
        <X className="w-6 h-6" />
      </button>
      
      <div className="text-[12vw] font-bold tracking-tighter text-gray-800 dark:text-white font-mono">
        {format(time, is24Hour ? 'HH:mm:ss' : 'hh:mm:ss a')}
      </div>
      
      <div className="text-[4vw] text-gray-500 dark:text-gray-400 mt-4">
        {format(time, 'EEEE, MMMM do, yyyy')}
      </div>
    </div>
  );
};