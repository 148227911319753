import React, { useEffect } from 'react';
import { Play, Pause, RotateCcw } from 'lucide-react';
import { usePomodoroStore } from '../../store/pomodoroStore';
import useSound from 'use-sound';

const TIMER_COMPLETE_SOUND = 'https://actions.google.com/sounds/v1/alarms/beep_short.ogg';

export const Timer: React.FC = () => {
  const {
    isRunning,
    timeLeft,
    mode,
    startTimer,
    pauseTimer,
    resetTimer,
    completeSession,
  } = usePomodoroStore();

  const [playSound] = useSound(TIMER_COMPLETE_SOUND, { volume: 0.5 });

  useEffect(() => {
    let interval: NodeJS.Timeout;
    
    if (isRunning && timeLeft > 0) {
      interval = setInterval(() => {
        usePomodoroStore.setState((state) => ({
          timeLeft: state.timeLeft - 1,
        }));
      }, 1000);
    } else if (timeLeft === 0 && isRunning) {
      playSound();
      completeSession();
      
      if (Notification.permission === 'granted') {
        new Notification('Pomodoro Timer', {
          body: `${mode === 'work' ? 'Work session' : 'Break'} completed!`,
          icon: '/tomato.svg',
        });
      }
    }

    return () => clearInterval(interval);
  }, [isRunning, timeLeft, mode, completeSession, playSound]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const getBackgroundColor = () => {
    switch (mode) {
      case 'work':
        return 'bg-red-500';
      case 'shortBreak':
        return 'bg-green-500';
      case 'longBreak':
        return 'bg-blue-500';
    }
  };

  return (
    <div className="flex flex-col items-center space-y-6">
      <div className={`w-48 h-48 rounded-full ${getBackgroundColor()} flex items-center justify-center transition-colors`}>
        <div className="text-5xl font-mono text-white font-bold">
          {formatTime(timeLeft)}
        </div>
      </div>

      <div className="flex space-x-4">
        <button
          onClick={isRunning ? pauseTimer : startTimer}
          className="p-4 rounded-full bg-white text-gray-800 hover:bg-gray-100 transition-colors shadow-lg"
        >
          {isRunning ? <Pause className="w-6 h-6" /> : <Play className="w-6 h-6" />}
        </button>
        <button
          onClick={resetTimer}
          className="p-4 rounded-full bg-white text-gray-800 hover:bg-gray-100 transition-colors shadow-lg"
        >
          <RotateCcw className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};