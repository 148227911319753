import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { AVAILABLE_TIMEZONES, TimeZoneData } from '../data/timezones';

const DEFAULT_TIMEZONES = AVAILABLE_TIMEZONES.filter(tz => 
  ['london', 'newyork', 'tokyo'].includes(tz.id)
);

interface WorldClockStore {
  timezones: TimeZoneData[];
  addTimeZone: (timezone: TimeZoneData) => void;
  removeTimeZone: (id: string) => void;
}

export const useWorldClockStore = create<WorldClockStore>()(
  persist(
    (set) => ({
      timezones: DEFAULT_TIMEZONES,
      addTimeZone: (timezone) =>
        set((state) => ({
          timezones: [...state.timezones, timezone],
        })),
      removeTimeZone: (id) =>
        set((state) => ({
          timezones: state.timezones.filter((tz) => tz.id !== id),
        })),
    }),
    {
      name: 'world-clock-storage',
    }
  )
);