import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Clock as ClockIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ar, bn, de, enUS, es, fr, hi, it, ja, ko, pt, ru, tr, zhCN } from 'date-fns/locale';

interface ClockProps {
  is24Hour: boolean;
  onToggleFormat: () => void;
}

const locales = {
  en: enUS,
  es,
  fr,
  de,
  it,
  pt,
  ru,
  ja,
  ko,
  zh: zhCN,
  ar,
  hi,
  tr,
  bn
};

export const Clock: React.FC<ClockProps> = ({ is24Hour, onToggleFormat }) => {
  const [time, setTime] = useState(new Date());
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const currentLocale = locales[i18n.language as keyof typeof locales] || enUS;

  return (
    <div className="flex flex-col items-center space-y-4 p-8 bg-white dark:bg-gray-800 rounded-2xl shadow-lg">
      <div className="flex items-center space-x-2">
        <ClockIcon className="w-8 h-8 text-blue-500" />
        <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">
          {t('clock.currentTime')}
        </h2>
      </div>
      
      <div className="text-6xl font-bold tracking-tighter text-gray-800 dark:text-white font-mono">
        {format(time, is24Hour ? 'HH:mm:ss' : 'hh:mm:ss a')}
      </div>
      
      <button
        onClick={onToggleFormat}
        className="px-4 py-2 text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
      >
        {t('clock.toggle24Hour')}
      </button>
      
      <div className="text-sm text-gray-500 dark:text-gray-400">
        {format(time, 'EEEE, MMMM do, yyyy', { locale: currentLocale })}
      </div>
    </div>
  );
};