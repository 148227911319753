import React, { useState } from 'react';
import { Timer } from './pomodoro/Timer';
import { ModeSelector } from './pomodoro/ModeSelector';
import { TaskList } from './pomodoro/TaskList';
import { Settings } from './pomodoro/Settings';
import { Settings as SettingsIcon } from 'lucide-react';
import { usePomodoroStore } from '../store/pomodoroStore';

export const PomodoroTimer: React.FC = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const mode = usePomodoroStore((state) => state.mode);

  const getBackgroundColor = () => {
    switch (mode) {
      case 'work':
        return 'bg-red-600';
      case 'shortBreak':
        return 'bg-green-600';
      case 'longBreak':
        return 'bg-blue-600';
    }
  };

  return (
    <div className={`w-full rounded-lg p-4 sm:p-6 ${getBackgroundColor()} transition-colors`}>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg sm:text-xl font-semibold text-white truncate mr-2">
          Pomodoro Timer
        </h2>
        <button
          onClick={() => setIsSettingsOpen(true)}
          className="p-2 rounded-lg text-white/80 hover:bg-white/10 transition-colors flex-shrink-0"
        >
          <SettingsIcon className="w-5 h-5" />
        </button>
      </div>

      <div className="flex flex-col items-center space-y-6">
        <ModeSelector />
        <Timer />
        <TaskList />
      </div>

      <Settings isOpen={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
    </div>
  );
};