import React, { useEffect } from 'react';
import useSound from 'use-sound';
import { useAlarmStore } from '../store/alarmStore';

const ALARM_SOUND_URL = 'https://actions.google.com/sounds/v1/alarms/mechanical_clock.ogg';

export const AlarmSound: React.FC = () => {
  const [play, { stop }] = useSound(ALARM_SOUND_URL, {
    volume: 0.75,
    interrupt: true,
    loop: true,
  });
  
  const { alarms, checkAlarms } = useAlarmStore();
  
  useEffect(() => {
    const checkInterval = setInterval(() => {
      const triggeredAlarms = checkAlarms();
      if (triggeredAlarms.length > 0) {
        play();
        
        if (Notification.permission === 'granted') {
          new Notification('Alarm!', {
            body: 'Your alarm is ringing!',
            icon: '/alarm-clock.svg',
          });
        } else if (Notification.permission !== 'denied') {
          Notification.requestPermission();
        }
      }
    }, 1000);

    return () => {
      clearInterval(checkInterval);
      stop();
    };
  }, [play, stop, checkAlarms]);

  useEffect(() => {
    if (!alarms.some(alarm => alarm.isPlaying)) {
      stop();
    }
  }, [alarms, stop]);

  return null;
};