import React, { useState } from 'react';
import { Plus, Check, Trash2, Circle } from 'lucide-react';
import { usePomodoroStore } from '../../store/pomodoroStore';

export const TaskList: React.FC = () => {
  const { tasks, addTask, updateTask, deleteTask, activeTaskId, setActiveTask } = usePomodoroStore();
  const [newTaskTitle, setNewTaskTitle] = useState('');

  const handleAddTask = (e: React.FormEvent) => {
    e.preventDefault();
    if (!newTaskTitle.trim()) return;

    addTask({
      title: newTaskTitle,
      estimatedPomodoros: 1,
      completedPomodoros: 0,
      completed: false,
    });
    setNewTaskTitle('');
  };

  return (
    <div className="w-full bg-white/10 rounded-lg p-4 space-y-4">
      <form onSubmit={handleAddTask} className="flex space-x-2">
        <input
          type="text"
          value={newTaskTitle}
          onChange={(e) => setNewTaskTitle(e.target.value)}
          placeholder="What are you working on?"
          className="flex-1 px-4 py-2 rounded-lg bg-white/5 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/20"
        />
        <button
          type="submit"
          className="p-2 rounded-lg bg-white/10 text-white hover:bg-white/20 transition-colors"
        >
          <Plus className="w-5 h-5" />
        </button>
      </form>

      <div className="space-y-2 max-h-48 overflow-y-auto">
        {tasks.map((task) => (
          <div
            key={task.id}
            className={`flex items-center space-x-3 p-3 rounded-lg transition-colors ${
              activeTaskId === task.id ? 'bg-white/20' : 'hover:bg-white/10'
            }`}
          >
            <button
              onClick={() => updateTask(task.id, { completed: !task.completed })}
              className="text-white/80 hover:text-white"
            >
              {task.completed ? (
                <Check className="w-5 h-5" />
              ) : (
                <Circle className="w-5 h-5" />
              )}
            </button>
            
            <div
              className="flex-1 cursor-pointer"
              onClick={() => setActiveTask(activeTaskId === task.id ? null : task.id)}
            >
              <div className={`text-white ${task.completed ? 'line-through opacity-50' : ''}`}>
                {task.title}
              </div>
              <div className="text-sm text-white/50">
                {task.completedPomodoros}/{task.estimatedPomodoros} pomodoros
              </div>
            </div>

            <button
              onClick={() => deleteTask(task.id)}
              className="text-white/50 hover:text-white"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};