import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { isWithinInterval, subMinutes, addMinutes } from 'date-fns';

interface Alarm {
  id: string;
  time: string;
  enabled: boolean;
  days: number[];
  sound: string;
  label: string;
  isPlaying: boolean;
}

interface AlarmStore {
  alarms: Alarm[];
  addAlarm: (alarm: Omit<Alarm, 'id' | 'isPlaying'>) => void;
  removeAlarm: (id: string) => void;
  toggleAlarm: (id: string) => void;
  updateAlarm: (id: string, alarm: Partial<Alarm>) => void;
  checkAlarms: () => string[];
  stopAlarm: (id: string) => void;
}

export const useAlarmStore = create<AlarmStore>()(
  persist(
    (set) => ({
      alarms: [],
      addAlarm: (alarm) =>
        set((state) => ({
          alarms: [...state.alarms, { ...alarm, id: crypto.randomUUID(), isPlaying: false }],
        })),
      removeAlarm: (id) =>
        set((state) => ({
          alarms: state.alarms.filter((alarm) => alarm.id !== id),
        })),
      toggleAlarm: (id) =>
        set((state) => ({
          alarms: state.alarms.map((alarm) =>
            alarm.id === id ? { ...alarm, enabled: !alarm.enabled } : alarm
          ),
        })),
      updateAlarm: (id, updatedAlarm) =>
        set((state) => ({
          alarms: state.alarms.map((alarm) =>
            alarm.id === id ? { ...alarm, ...updatedAlarm } : alarm
          ),
        })),
      checkAlarms: () => {
        const now = new Date();
        const triggeredAlarms: string[] = [];
        
        set((state) => ({
          alarms: state.alarms.map((alarm) => {
            if (!alarm.enabled || alarm.isPlaying) return alarm;
            
            const alarmTime = new Date();
            const [hours, minutes] = alarm.time.split(':');
            alarmTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);
            
            const isInTimeWindow = isWithinInterval(now, {
              start: subMinutes(alarmTime, 1),
              end: addMinutes(alarmTime, 1),
            });
            
            if (isInTimeWindow) {
              triggeredAlarms.push(alarm.id);
              return { ...alarm, isPlaying: true };
            }
            
            return alarm;
          }),
        }));
        
        return triggeredAlarms;
      },
      stopAlarm: (id) =>
        set((state) => ({
          alarms: state.alarms.map((alarm) =>
            alarm.id === id ? { ...alarm, isPlaying: false } : alarm
          ),
        })),
    }),
    {
      name: 'alarm-storage',
    }
  )
);