import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Clock } from './components/Clock';
import { AlarmList } from './components/AlarmList';
import { AddAlarm } from './components/AddAlarm';
import { AlarmSound } from './components/AlarmSound';
import { FullScreenClock } from './components/FullScreenClock';
import { Stopwatch } from './components/Stopwatch';
import { CountdownTimer } from './components/CountdownTimer';
import { WorldClocks } from './components/WorldClocks';
import { PomodoroTimer } from './components/PomodoroTimer';
import { LanguageSelector } from './components/LanguageSelector';
import { Moon, Sun, Maximize2 } from 'lucide-react';

type Tab = 'alarms' | 'stopwatch' | 'timer' | 'pomodoro';

function App() {
  const { t } = useTranslation();
  const [darkMode, setDarkMode] = React.useState(false);
  const [is24Hour, setIs24Hour] = React.useState(true);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<Tab>('alarms');

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const TabButton: React.FC<{ tab: Tab; current: Tab; onClick: () => void; children: React.ReactNode }> = ({
    tab,
    current,
    onClick,
    children
  }) => (
    <button
      onClick={onClick}
      className={`px-2.5 py-1.5 text-sm font-medium rounded-lg transition-colors whitespace-nowrap ${
        tab === current
          ? 'bg-blue-500 text-white'
          : 'text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700'
      }`}
    >
      {children}
    </button>
  );

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors">
      {isFullScreen && (
        <FullScreenClock
          is24Hour={is24Hour}
          onClose={() => setIsFullScreen(false)}
        />
      )}
      
      <div className="container mx-auto px-4 py-8">
        <header className="flex justify-between items-center mb-8">
          <h1 className="text-lg sm:text-xl font-bold text-gray-900 dark:text-white">
            {t('app.title')}
          </h1>
          <div className="flex space-x-2">
            <LanguageSelector />
            <button
              onClick={() => setIsFullScreen(true)}
              className="p-2 rounded-lg bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
              title={t('app.fullScreen')}
            >
              <Maximize2 className="w-5 h-5" />
            </button>
            <button
              onClick={() => setDarkMode(!darkMode)}
              className="p-2 rounded-lg bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
              title={t('app.darkMode')}
            >
              {darkMode ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
            </button>
          </div>
        </header>

        <main className="flex flex-col items-center space-y-8">
          <Clock
            is24Hour={is24Hour}
            onToggleFormat={() => setIs24Hour(!is24Hour)}
          />
          
          <WorldClocks is24Hour={is24Hour} />
          
          <div className="w-full max-w-md">
            <div className="flex justify-center gap-1.5 mb-6 bg-gray-100 dark:bg-gray-800 p-1.5 rounded-lg">
              <TabButton tab="alarms" current={activeTab} onClick={() => setActiveTab('alarms')}>
                {t('tabs.alarms')}
              </TabButton>
              <TabButton tab="stopwatch" current={activeTab} onClick={() => setActiveTab('stopwatch')}>
                {t('tabs.stopwatch')}
              </TabButton>
              <TabButton tab="timer" current={activeTab} onClick={() => setActiveTab('timer')}>
                {t('tabs.timer')}
              </TabButton>
              <TabButton tab="pomodoro" current={activeTab} onClick={() => setActiveTab('pomodoro')}>
                {t('tabs.pomodoro')}
              </TabButton>
            </div>

            {activeTab === 'alarms' && (
              <>
                <AddAlarm />
                <div className="mt-6">
                  <AlarmList />
                </div>
              </>
            )}
            {activeTab === 'stopwatch' && <Stopwatch />}
            {activeTab === 'timer' && <CountdownTimer />}
            {activeTab === 'pomodoro' && <PomodoroTimer />}
          </div>
          
          <AlarmSound />
        </main>
      </div>
    </div>
  );
}

export default App;