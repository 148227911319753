export interface TimeZoneData {
  city: string;
  country: string;
  timezone: string;
  gmtOffset: string;
  id: string;
}

// Ensure each timezone has a unique ID
export const AVAILABLE_TIMEZONES: TimeZoneData[] = [
  // Europe
  { id: 'london', city: 'London', country: 'United Kingdom', timezone: 'Europe/London', gmtOffset: 'GMT+0' },
  { id: 'paris', city: 'Paris', country: 'France', timezone: 'Europe/Paris', gmtOffset: 'GMT+1' },
  { id: 'berlin', city: 'Berlin', country: 'Germany', timezone: 'Europe/Berlin', gmtOffset: 'GMT+1' },
  { id: 'rome', city: 'Rome', country: 'Italy', timezone: 'Europe/Rome', gmtOffset: 'GMT+1' },
  { id: 'madrid', city: 'Madrid', country: 'Spain', timezone: 'Europe/Madrid', gmtOffset: 'GMT+1' },
  { id: 'amsterdam', city: 'Amsterdam', country: 'Netherlands', timezone: 'Europe/Amsterdam', gmtOffset: 'GMT+1' },
  { id: 'brussels', city: 'Brussels', country: 'Belgium', timezone: 'Europe/Brussels', gmtOffset: 'GMT+1' },
  { id: 'vienna', city: 'Vienna', country: 'Austria', timezone: 'Europe/Vienna', gmtOffset: 'GMT+1' },
  { id: 'stockholm', city: 'Stockholm', country: 'Sweden', timezone: 'Europe/Stockholm', gmtOffset: 'GMT+1' },
  { id: 'oslo', city: 'Oslo', country: 'Norway', timezone: 'Europe/Oslo', gmtOffset: 'GMT+1' },
  { id: 'copenhagen', city: 'Copenhagen', country: 'Denmark', timezone: 'Europe/Copenhagen', gmtOffset: 'GMT+1' },
  { id: 'warsaw', city: 'Warsaw', country: 'Poland', timezone: 'Europe/Warsaw', gmtOffset: 'GMT+1' },
  { id: 'budapest', city: 'Budapest', country: 'Hungary', timezone: 'Europe/Budapest', gmtOffset: 'GMT+1' },
  { id: 'athens', city: 'Athens', country: 'Greece', timezone: 'Europe/Athens', gmtOffset: 'GMT+2' },
  { id: 'moscow', city: 'Moscow', country: 'Russia', timezone: 'Europe/Moscow', gmtOffset: 'GMT+3' },

  // Americas
  { id: 'newyork', city: 'New York', country: 'United States', timezone: 'America/New_York', gmtOffset: 'GMT-4' },
  { id: 'washington', city: 'Washington DC', country: 'United States', timezone: 'America/New_York', gmtOffset: 'GMT-4' },
  { id: 'losangeles', city: 'Los Angeles', country: 'United States', timezone: 'America/Los_Angeles', gmtOffset: 'GMT-7' },
  { id: 'chicago', city: 'Chicago', country: 'United States', timezone: 'America/Chicago', gmtOffset: 'GMT-5' },
  { id: 'toronto', city: 'Toronto', country: 'Canada', timezone: 'America/Toronto', gmtOffset: 'GMT-4' },
  { id: 'vancouver', city: 'Vancouver', country: 'Canada', timezone: 'America/Vancouver', gmtOffset: 'GMT-7' },
  { id: 'mexicocity', city: 'Mexico City', country: 'Mexico', timezone: 'America/Mexico_City', gmtOffset: 'GMT-6' },
  { id: 'saopaulo', city: 'São Paulo', country: 'Brazil', timezone: 'America/Sao_Paulo', gmtOffset: 'GMT-3' },
  { id: 'buenosaires', city: 'Buenos Aires', country: 'Argentina', timezone: 'America/Argentina/Buenos_Aires', gmtOffset: 'GMT-3' },
  { id: 'lima', city: 'Lima', country: 'Peru', timezone: 'America/Lima', gmtOffset: 'GMT-5' },
  { id: 'bogota', city: 'Bogotá', country: 'Colombia', timezone: 'America/Bogota', gmtOffset: 'GMT-5' },
  { id: 'santiago', city: 'Santiago', country: 'Chile', timezone: 'America/Santiago', gmtOffset: 'GMT-4' },

  // Asia
  { id: 'tokyo', city: 'Tokyo', country: 'Japan', timezone: 'Asia/Tokyo', gmtOffset: 'GMT+9' },
  { id: 'beijing', city: 'Beijing', country: 'China', timezone: 'Asia/Shanghai', gmtOffset: 'GMT+8' },
  { id: 'shanghai', city: 'Shanghai', country: 'China', timezone: 'Asia/Shanghai', gmtOffset: 'GMT+8' },
  { id: 'hongkong', city: 'Hong Kong', country: 'China', timezone: 'Asia/Hong_Kong', gmtOffset: 'GMT+8' },
  { id: 'singapore', city: 'Singapore', country: 'Singapore', timezone: 'Asia/Singapore', gmtOffset: 'GMT+8' },
  { id: 'seoul', city: 'Seoul', country: 'South Korea', timezone: 'Asia/Seoul', gmtOffset: 'GMT+9' },
  { id: 'mumbai', city: 'Mumbai', country: 'India', timezone: 'Asia/Kolkata', gmtOffset: 'GMT+5:30' },
  { id: 'newdelhi', city: 'New Delhi', country: 'India', timezone: 'Asia/Kolkata', gmtOffset: 'GMT+5:30' },
  { id: 'bangkok', city: 'Bangkok', country: 'Thailand', timezone: 'Asia/Bangkok', gmtOffset: 'GMT+7' },
  { id: 'jakarta', city: 'Jakarta', country: 'Indonesia', timezone: 'Asia/Jakarta', gmtOffset: 'GMT+7' },
  { id: 'manila', city: 'Manila', country: 'Philippines', timezone: 'Asia/Manila', gmtOffset: 'GMT+8' },
  { id: 'kualalumpur', city: 'Kuala Lumpur', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', gmtOffset: 'GMT+8' },
  { id: 'dubai', city: 'Dubai', country: 'UAE', timezone: 'Asia/Dubai', gmtOffset: 'GMT+4' },
  { id: 'abudhabi', city: 'Abu Dhabi', country: 'UAE', timezone: 'Asia/Dubai', gmtOffset: 'GMT+4' },
  { id: 'istanbul', city: 'Istanbul', country: 'Turkey', timezone: 'Europe/Istanbul', gmtOffset: 'GMT+3' },
  { id: 'tehran', city: 'Tehran', country: 'Iran', timezone: 'Asia/Tehran', gmtOffset: 'GMT+3:30' },
  { id: 'riyadh', city: 'Riyadh', country: 'Saudi Arabia', timezone: 'Asia/Riyadh', gmtOffset: 'GMT+3' },

  // Oceania
  { id: 'sydney', city: 'Sydney', country: 'Australia', timezone: 'Australia/Sydney', gmtOffset: 'GMT+10' },
  { id: 'melbourne', city: 'Melbourne', country: 'Australia', timezone: 'Australia/Melbourne', gmtOffset: 'GMT+10' },
  { id: 'brisbane', city: 'Brisbane', country: 'Australia', timezone: 'Australia/Brisbane', gmtOffset: 'GMT+10' },
  { id: 'perth', city: 'Perth', country: 'Australia', timezone: 'Australia/Perth', gmtOffset: 'GMT+8' },
  { id: 'auckland', city: 'Auckland', country: 'New Zealand', timezone: 'Pacific/Auckland', gmtOffset: 'GMT+12' },
  { id: 'wellington', city: 'Wellington', country: 'New Zealand', timezone: 'Pacific/Auckland', gmtOffset: 'GMT+12' },

  // Africa
  { id: 'cairo', city: 'Cairo', country: 'Egypt', timezone: 'Africa/Cairo', gmtOffset: 'GMT+2' },
  { id: 'johannesburg', city: 'Johannesburg', country: 'South Africa', timezone: 'Africa/Johannesburg', gmtOffset: 'GMT+2' },
  { id: 'lagos', city: 'Lagos', country: 'Nigeria', timezone: 'Africa/Lagos', gmtOffset: 'GMT+1' },
  { id: 'nairobi', city: 'Nairobi', country: 'Kenya', timezone: 'Africa/Nairobi', gmtOffset: 'GMT+3' },
  { id: 'casablanca', city: 'Casablanca', country: 'Morocco', timezone: 'Africa/Casablanca', gmtOffset: 'GMT+1' },
  { id: 'addisababa', city: 'Addis Ababa', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', gmtOffset: 'GMT+3' },
];