import React from 'react';
import { Bell, Trash2, Edit, Volume2 } from 'lucide-react';
import { useAlarmStore } from '../store/alarmStore';

export const AlarmList: React.FC = () => {
  const { alarms, toggleAlarm, removeAlarm, stopAlarm } = useAlarmStore();

  return (
    <div className="space-y-4 w-full max-w-md">
      {alarms.map((alarm) => (
        <div
          key={alarm.id}
          className={`flex items-center justify-between p-4 bg-white dark:bg-gray-800 rounded-xl shadow-md ${
            alarm.isPlaying ? 'animate-pulse-slow ring-2 ring-blue-500' : ''
          }`}
        >
          <div className="flex items-center space-x-4">
            <button
              onClick={() => toggleAlarm(alarm.id)}
              className={`p-2 rounded-full transition-colors ${
                alarm.enabled
                  ? 'bg-blue-100 text-blue-500 dark:bg-blue-900'
                  : 'bg-gray-100 text-gray-400 dark:bg-gray-700'
              }`}
            >
              <Bell className="w-5 h-5" />
            </button>
            
            <div>
              <div className="text-lg font-semibold dark:text-white">
                {alarm.time}
              </div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {alarm.label}
              </div>
            </div>
          </div>
          
          <div className="flex space-x-2">
            {alarm.isPlaying && (
              <button
                onClick={() => stopAlarm(alarm.id)}
                className="p-2 text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 transition-colors"
              >
                <Volume2 className="w-5 h-5 animate-pulse" />
              </button>
            )}
            <button
              className="p-2 text-gray-500 hover:text-blue-500 dark:text-gray-400 dark:hover:text-blue-400 transition-colors"
            >
              <Edit className="w-5 h-5" />
            </button>
            <button
              onClick={() => removeAlarm(alarm.id)}
              className="p-2 text-gray-500 hover:text-red-500 dark:text-gray-400 dark:hover:text-red-400 transition-colors"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};