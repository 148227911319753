import React, { useState, useEffect } from 'react';
import { Play, Pause, RotateCcw, Plus, Minus, VolumeX } from 'lucide-react';
import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';

const TIMER_SOUND_URL = 'https://actions.google.com/sounds/v1/alarms/beep_short.ogg';

export const CountdownTimer: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState(300);
  const [isRunning, setIsRunning] = useState(false);
  const [initialTime, setInitialTime] = useState(300);
  const [isPlaying, setIsPlaying] = useState(false);
  const { t } = useTranslation();

  const [play, { stop }] = useSound(TIMER_SOUND_URL, {
    volume: 0.75,
    interrupt: true,
  });

  useEffect(() => {
    let interval: number;
    if (isRunning && timeLeft > 0) {
      interval = window.setInterval(() => setTimeLeft(t => t - 1), 1000);
    } else if (timeLeft === 0 && isRunning) {
      setIsRunning(false);
      setIsPlaying(true);
      play();
      
      if (Notification.permission === 'granted') {
        new Notification(t('timer.complete'), {
          body: t('timer.completeMessage'),
          icon: '/tomato.svg',
        });
      }
    }
    return () => clearInterval(interval);
  }, [isRunning, timeLeft, play, t]);

  const handleReset = () => {
    setTimeLeft(initialTime);
    setIsRunning(false);
    setIsPlaying(false);
    stop();
  };

  const adjustTime = (seconds: number) => {
    if (!isRunning) {
      const newTime = Math.max(0, initialTime + seconds);
      setInitialTime(newTime);
      setTimeLeft(newTime);
    }
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const stopSound = () => {
    setIsPlaying(false);
    stop();
  };

  return (
    <div className="space-y-6">
      <div className="text-6xl font-mono text-center text-gray-800 dark:text-white">
        {formatTime(timeLeft)}
      </div>

      <div className="flex justify-center space-x-4">
        <button
          onClick={() => adjustTime(-60)}
          disabled={isRunning}
          className="p-3 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors disabled:opacity-50"
        >
          <Minus className="w-6 h-6" />
        </button>
        <button
          onClick={() => setIsRunning(!isRunning)}
          className="p-3 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors"
        >
          {isRunning ? <Pause className="w-6 h-6" /> : <Play className="w-6 h-6" />}
        </button>
        <button
          onClick={handleReset}
          className="p-3 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
        >
          <RotateCcw className="w-6 h-6" />
        </button>
        <button
          onClick={() => adjustTime(60)}
          disabled={isRunning}
          className="p-3 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors disabled:opacity-50"
        >
          <Plus className="w-6 h-6" />
        </button>
        {isPlaying && (
          <button
            onClick={stopSound}
            className="p-3 rounded-full bg-red-500 text-white hover:bg-red-600 transition-colors animate-pulse"
          >
            <VolumeX className="w-6 h-6" />
          </button>
        )}
      </div>

      <div className="flex justify-center space-x-4">
        {[5, 10, 15, 30].map(minutes => (
          <button
            key={minutes}
            onClick={() => {
              if (!isRunning) {
                setInitialTime(minutes * 60);
                setTimeLeft(minutes * 60);
              }
            }}
            disabled={isRunning}
            className="px-4 py-2 rounded-lg bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors disabled:opacity-50"
          >
            {minutes}m
          </button>
        ))}
      </div>
    </div>
  );
};