import React, { useState } from 'react';
import { PlusCircle } from 'lucide-react';
import { useAlarmStore } from '../store/alarmStore';
import { useTranslation } from 'react-i18next';

export const AddAlarm: React.FC = () => {
  const [time, setTime] = useState('');
  const [label, setLabel] = useState('');
  const addAlarm = useAlarmStore((state) => state.addAlarm);
  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!time) return;

    addAlarm({
      time,
      label,
      enabled: true,
      days: [],
      sound: 'default',
    });

    setTime('');
    setLabel('');
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 w-full max-w-md">
      <div className="flex flex-col space-y-2">
        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('alarms.time')}
        </label>
        <input
          type="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 outline-none"
          required
        />
      </div>

      <div className="flex flex-col space-y-2">
        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('alarms.label')}
        </label>
        <input
          type="text"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          placeholder={t('alarms.addLabel')}
          className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 outline-none"
        />
      </div>

      <button
        type="submit"
        className="w-full flex items-center justify-center space-x-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
      >
        <PlusCircle className="w-5 h-5" />
        <span>{t('alarms.addAlarm')}</span>
      </button>
    </form>
  );
};