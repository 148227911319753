import React, { useState } from 'react';
import { format } from 'date-fns';
import { Globe, Plus, X, Search } from 'lucide-react';
import { useWorldClockStore } from '../store/worldClockStore';
import { AVAILABLE_TIMEZONES } from '../data/timezones';
import { useTranslation } from 'react-i18next';
import * as locales from 'date-fns/locale';

interface WorldClocksProps {
  is24Hour: boolean;
}

type DateLocale = keyof typeof locales;

const getLocale = (language: string): DateLocale => {
  const localeMap: Record<string, DateLocale> = {
    en: 'enUS',
    es: 'es',
    fr: 'fr',
    de: 'de',
    it: 'it',
    pt: 'pt',
    ru: 'ru',
    ja: 'ja',
    ko: 'ko',
    zh: 'zhCN',
    ar: 'ar',
    hi: 'hi',
    tr: 'tr',
    bn: 'bn'
  };
  return localeMap[language] || 'enUS';
};

export const WorldClocks: React.FC<WorldClocksProps> = ({ is24Hour }) => {
  const { timezones, addTimeZone, removeTimeZone } = useWorldClockStore();
  const [isAdding, setIsAdding] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [time, setTime] = React.useState(new Date());
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const getTimeInTimezone = (timezone: string) => {
    return new Date(time.toLocaleString('en-US', { timeZone: timezone }));
  };

  const dateLocale = getLocale(i18n.language);

  const filteredTimezones = AVAILABLE_TIMEZONES.filter(
    (tz) =>
      !timezones.some((userTz) => userTz.id === tz.id) &&
      (tz.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tz.country.toLowerCase().includes(searchQuery.toLowerCase()))
  ).sort((a, b) => a.city.localeCompare(b.city));

  return (
    <div className="w-full max-w-3xl bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <Globe className="w-5 h-5 text-blue-500" />
          <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
            {t('worldClock.title')}
          </h2>
        </div>
        <button
          onClick={() => setIsAdding(!isAdding)}
          className="p-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors"
          title={t('worldClock.addClock')}
        >
          <Plus className="w-5 h-5" />
        </button>
      </div>

      {isAdding && (
        <div className="mb-6 p-4 bg-gray-50 dark:bg-gray-700 rounded-xl">
          <div className="flex items-center space-x-2 mb-4">
            <Search className="w-5 h-5 text-gray-400" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('worldClock.searchPlaceholder')}
              className="w-full bg-transparent border-none focus:ring-0 text-gray-800 dark:text-white placeholder-gray-400"
            />
          </div>
          <div className="max-h-48 overflow-y-auto space-y-2">
            {filteredTimezones.map((tz) => (
              <button
                key={tz.id}
                onClick={() => {
                  addTimeZone(tz);
                  setIsAdding(false);
                  setSearchQuery('');
                }}
                className="w-full p-2 text-left rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <span className="font-medium text-gray-800 dark:text-white">
                      {tz.city}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      , {tz.country}
                    </span>
                  </div>
                  <span className="text-xs font-mono text-blue-500 dark:text-blue-400">
                    {tz.gmtOffset}
                  </span>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {timezones.map((tz) => (
          <div
            key={tz.id}
            className="p-4 bg-gray-50 dark:bg-gray-700 rounded-xl transition-transform hover:scale-105 group relative"
          >
            <button
              onClick={() => removeTimeZone(tz.id)}
              className="absolute top-2 right-2 p-1 rounded-full bg-red-100 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-200"
            >
              <X className="w-4 h-4" />
            </button>
            <div className="flex flex-col">
              <div className="flex justify-between items-start mb-2">
                <div>
                  <h3 className="font-semibold text-gray-800 dark:text-white">
                    {tz.city}
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {tz.country}
                  </p>
                </div>
                <span className="text-xs font-mono text-blue-500 dark:text-blue-400">
                  {tz.gmtOffset}
                </span>
              </div>
              <div className="font-mono text-2xl text-gray-800 dark:text-white">
                {format(
                  getTimeInTimezone(tz.timezone),
                  is24Hour ? 'HH:mm:ss' : 'hh:mm:ss a',
                  { locale: locales[dateLocale] }
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};